export const superAbility = `Type\tAbility\tDuration\tDamage\tDPS
Arc\tArc Staff\t13.8\t137696\t9956
Arc\tChaos Reach\t5.1\t145558\t28541
Arc:[ Geomag Stabilizers ]\tChaos Reach\t9.1\t300850\t33060
Arc\tFists of Havoc\t9.8\t259875\t26572
Arc:[ Synthoceps ]\tFists of Havoc\t9.8\t389812\t39858
Arc\tGathering Storm\t2.0\t167925\t86116
Arc:[ Star-Eater Scales ]\tGathering Storm\t2.0\t270044\t138484
Arc\tStormtrance\t13.6\t211040\t15540
Arc\tThundercrash\t1.9\t111225\t58539
Arc:[ Cuirass of the Falling Star ]\tThundercrash\t1.9\t222335\t117019
Solar\tBlade Barrage\t2.0\t174294\t88474
Solar:[ Star-Eater Scales ]\tBlade Barrage\t2.0\t296300\t150406
Solar\tBurning Maul\t10.9\t177957\t16371
Solar:[ Pyrogale Gauntlets ]\tBurning Maul\t2.9\t177957\t61791
Solar:[ Pyrogale Gauntlets | Roaring Flames x3 ]\tBurning Maul\t2.9\t307864\t106897
Solar\tDaybreak\t9.5\t150313\t15873
Solar:[ Dawn Chorus ]\tDaybreak\t9.5\t368846\t38949
Solar:[ Celestial Nighthawk ]\tGolden Gun\t2.2\t236452\t108964
Solar:[ Deadshot ]\tGolden Gun\t3.6\t87304\t24117
Solar:[ Marksman ]\tGolden Gun\t3.4\t137419\t40657
Solar:[ Star-Eater Scales ]\tGolden Gun\t3.4\t233611\t69116
Solar\tHammer of Sol\t12.2\t194483\t16007
Solar:[ Roaring Flames x3 ]\tHammer of Sol\t12.2\t285198\t23473
Stasis\tGlacial Quake\t12.9\t364292\t28350
Stasis:[ Synthoceps ]\tGlacial Quake\t12.9\t546439\t42524
Stasis\tSilence and Squall\t2.3\t97144\t41693
Stasis\tWinter's Wrath\t14.4\t278201\t19279
Stasis:[ Ballidorse Wrathweavers ]\tWinter's Wrath\t14.4\t482411\t33431
Strand\tBladefury\t9.8\t228316\t23393
Strand:[ Banner of War ]\tBladefury\t9.8\t319643\t32750
Strand:[ Synthoceps | Banner of War ]\tBladefury\t9.8\t410970\t42108
Strand\tNeedlestorm\t1.9\t186134\t97965
Strand\tSilkstrike\t11.7\t245347\t21006
Strand:[ Star-Eater Scales ]\tSilkstrike\t11.7\t417089\t35710
Void:[ Cataclysm ]\tNova Bomb\t1.6\t187633\t117271
Void:[ Vortex ]\tNova Bomb\t1.6\t185352\t115845
Void\tNova Warp\t14.5\t243128\t16767
Void\tSentinel Shield\t11.4\t140627\t12357
Void:[ Synthoceps ]\tSentinel Shield\t11.4\t210940\t18536
Void:[ Deadfall ]\tShadowshot\t1.6\t21756\t13597
Void:[ Moebius Quiver ]\tShadowshot\t2.9\t118894\t40578
Void:[ Orpheus Rig ]\tShadowshot\t4.3\t181980\t42819
Void:[ Star-Eater Scales ]\tShadowshot\t2.9\t202119\t68983
Void\tSpectral Blades\t12.9\t159175\t12358`;
